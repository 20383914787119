import { Button, Grid, Stack, Typography } from "@mui/material";
import {
  Google as GoogleIcon,
  Home,
  TrendingUp as TrendingIcon
} from "@mui/icons-material";

import { useStore } from "../../../../service/mobx";
import { DiscordIcon } from "../../../Icon/Discord";
import { PaperIcon } from "../../../Icon/Papers";
import { ModelIcon } from "../../../Icon/Model";
import LabelWithIcon from "../../../LabelWithIcon";

export default function useSignInDialog(cta = "Ready to deploy AI?") {
  const { analytics, dialog, user } = useStore();

  return () => {
    analytics.track.event("User Auth");
    dialog.configure({
      sx: { paper: { bgcolor: "surface.container.lowest" } },
      Content() {
        const Section = ({ label, Icon }) => (
          <Grid item compact={6} display="flex" justifyContent="center">
            <LabelWithIcon label={label} Icon={Icon} />
          </Grid>
        );

        return (
          <Stack
            spacing={2}
            alignSelf="center"
            alignItems="center"
            justifyContent="center"
            pt={3}
            pb={2}
            width={360}
          >
            <Typography
              pb={1}
              align="center"
              variant="titleLg"
              fontWeight="bold"
              color="surface.on.variant"
            >
              {cta}
            </Typography>
            <Typography
              pt={0.25}
              variant="brand"
              color="#fff"
              align="center"
              fontSize={8 * 7}
              fontWeight="bold"
              bgcolor="#000"
              width={8 * 8}
              height={8 * 8}
              borderRadius={100}
              lineHeight={1}
            >
              b
            </Typography>
            <Stack spacing={1}>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                {[
                  ["Read papers", PaperIcon],
                  ["Demo models", ModelIcon],
                  ["Your AI feed", Home],
                  ["Follow trends", TrendingIcon]
                ].map(([label, Icon]) => (
                  <Section key={label} label={label} Icon={Icon} />
                ))}
              </Grid>
            </Stack>
            <Stack
              spacing={1}
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <ButtonSignIn
                label="Google"
                Icon={GoogleIcon}
                dialog={dialog}
                onClick={user.sign.in.google}
              />
              <ButtonSignIn
                label="Discord"
                Icon={DiscordIcon}
                dialog={dialog}
                onClick={user.sign.in.discord}
              />
            </Stack>
            <Typography variant="bodySm" align="center">
              By continuing you agree to{" "}
              <a href="/legal/terms" target="_blank">
                terms of service
              </a>{" "}
              and{" "}
              <a href="/legal/privacy" target="_blank">
                privacy policy
              </a>
            </Typography>
          </Stack>
        );
      }
    });
  };
}

export const LoginButtons = ({ dialog, user, sx }) => (
  <>
    <ButtonSignIn
      label="Google"
      Icon={GoogleIcon}
      dialog={dialog}
      onClick={user.sign.in.google}
      sx={sx}
    />
    <ButtonSignIn
      label="Discord"
      Icon={DiscordIcon}
      dialog={dialog}
      onClick={user.sign.in.discord}
      sx={sx}
    />
  </>
);
const ButtonSignIn = ({ dialog, label, Icon, onClick, sx = {} }) => (
  <Button
    fullWidth
    variant="filled"
    onClick={() => {
      onClick();
      dialog?.set.open(false);
    }}
    startIcon={<Icon sx={{ ml: 1, width: 18, height: 18 }} />}
    sx={theme => ({
      height: 40,
      bgcolor: "primary.container",
      borderRadius: theme.shape.round,
      pr: 3,
      color: "primary.on.container",
      typography: "labelLg",
      transition: theme.transitions.create("all"),
      "&:hover": {
        color: "primary.on.color",
        bgcolor: "primary.color"
      },
      ...sx
    })}
  >
    {label}
  </Button>
);
