"use client";
import { Grow } from "@mui/material";
import {
  StarBorderOutlined as StarIconOff,
  Star as StarIconOn
} from "@mui/icons-material";

import { observer, useStore } from "../../../service/mobx";
import Destination from "./Destination";

function MyStuff() {
  const { stars, user } = useStore();

  return (
    <Grow in={stars.show && user.isAnonymous === false}>
      <div>
        <Destination
          text="My stuff"
          path="/search"
          match="stars"
          params="?stars=all"
          Off={StarIconOff}
          On={StarIconOn}
        />
      </div>
    </Grow>
  );
}

export default observer(MyStuff);
