import { forwardRef, useState } from "react";
import Image from "next/image";
import { Avatar, Typography } from "@mui/material";

const NextAvatar = forwardRef(function NextAvatar(
  { src, alt, brokenBgcolor, fallback, size = 30, sx = {}, ...props },
  ref
) {
  const [error, setError] = useState(!src);

  return (
    <Avatar
      ref={ref}
      sx={{
        height: size,
        width: size,
        color: "surface.variant",
        position: "relative",
        bgcolor: brokenBgcolor
          ? error
            ? brokenBgcolor
            : "surface.variant"
          : "transparent",
        ...sx
      }}
      {...props}
    >
      {error ? (
        fallback || (
          <Typography variant="bodyMd" textTransform="capitalize">
            {alt?.[0]}
          </Typography>
        )
      ) : (
        <Image
          fill
          sizes={`${size}px`}
          alt={alt || "avatar"}
          src={src}
          referrerPolicy="no-referrer"
          style={{ objectFit: "cover" }}
          onError={() => setError(true)}
        />
      )}
    </Avatar>
  );
});

export default NextAvatar;
