"use client";
import { useTheme } from "@mui/material";

import AnonymousIcon from "mdi-react/IncognitoIcon";

import { useStore, observer } from "../../service/mobx";
import ToolTip from "../Tooltip";
import NextAvatar from ".";
import useSignInDialog from "../Dialog/dialogs/appWide/Login";

function LoginUserAvatar(props) {
  const { user } = useStore();
  const openDialog = useSignInDialog();

  return (
    <ToolTip title={`Sign ${user.isAnonymous ? "in" : "out"}`}>
      <UserAvatar
        onClick={
          user.loading
            ? undefined
            : () => {
                if (user.isAnonymous) {
                  openDialog();
                } else {
                  user.sign.out();
                }
              }
        }
        {...props}
      />
    </ToolTip>
  );
}

export default observer(LoginUserAvatar);
export const UserAvatar = observer(function UserAvatar(props) {
  const { user } = useStore();
  const theme = useTheme();

  return (
    <NextAvatar
      key={user.photo}
      src={user.photo}
      alt={user.name}
      fallback={
        <AnonymousIcon
          style={{ cursor: "pointer" }}
          color={theme.palette.surface.on.variant}
        />
      }
      {...props}
    />
  );
});
