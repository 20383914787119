"use client";
import { useEffect } from "react";
import dynamic from "next/dynamic";
import { useReportWebVitals } from "next/web-vitals";
import {
  useSelectedLayoutSegment,
  useSearchParams,
  usePathname
} from "next/navigation";
import { useMediaQuery, useTheme } from "@mui/material";
import {
  QuickreplyTwoTone as AIIcon,
  HomeOutlined as DiscoverIcon
} from "@mui/icons-material";

import { observer, useStore } from "../../service/mobx";
import { OffIcon } from "../../component/Star";
import { useLandingPage } from "../../view/about/Hooks";

const MobileAgent = dynamic(() =>
  import("../Agent/Client").then(module => module.MobileAgent)
);

function HooksAppWide() {
  const { analytics, bottomNav, device, dialog, reader, stars } = useStore();
  const route = useSelectedLayoutSegment() || "(discover)";
  const theme = useTheme();
  const isCompact = useMediaQuery(theme.breakpoints.only("compact"));
  const isMedium = useMediaQuery(theme.breakpoints.only("medium"));
  const isExpanded = useMediaQuery(theme.breakpoints.only("expanded"));
  const isLarge = useMediaQuery(theme.breakpoints.only("large"));
  const isXL = useMediaQuery(theme.breakpoints.only("extraLarge"));
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const url = `${pathname}${
    searchParams.size === 0 ? "" : "?" + searchParams.toString()
  }`;

  useLandingPage(route);

  useEffect(() => {
    device.set.size(
      isCompact
        ? "compact"
        : isMedium
        ? "medium"
        : isExpanded
        ? "expanded"
        : isLarge
        ? "large"
        : isXL
        ? "extraLarge"
        : ""
    );
  }, [device, isCompact, isMedium, isExpanded, isLarge, isXL]);

  useEffect(() => {
    if (device.isPhone) {
      const paperView = route === "docs" || route === "read";
      const actions = [
        paperView
          ? undefined
          : {
              label: "Discover",
              href: "/",
              route: "(discover)",
              Icon: DiscoverIcon
            },
        reader.whiteLabelled
          ? undefined
          : {
              label: "Ask AI",
              Icon: () => <AIIcon sx={{ color: "primary.color" }} />,
              onClick() {
                dialog.configure({
                  fullScreen: true,
                  Content: MobileAgent,
                  headline: "Ask Bytez",
                  sx: {
                    headline: { mx: 0, px: 1 },
                    paper: { p: 0, overflow: "hidden" },
                    firstStack: {
                      // overflow: "auto",
                      height: "inherit",
                      // alignItems: "stretch",
                      justifyContent: "space-between"
                    }
                  }
                });
              }
            },
        paperView === false && stars.show
          ? {
              label: "My stuff",
              href: "/search?stars=all",
              route: "search",
              Icon: OffIcon
            }
          : undefined
      ].filter(defined => defined);

      bottomNav.set.actions(actions);
      bottomNav.set.index(actions.findIndex(action => action.route === route));

      return bottomNav.reset;
    }
  }, [
    dialog,
    bottomNav,
    device.isPhone,
    stars.show,
    reader.whiteLabelled,
    route
  ]);

  useEffect(() => {
    try {
      const namespace = "history";
      const history = JSON.parse(sessionStorage.getItem(namespace) || "[]");
      const updatedHistory = new Set([url, ...history]);

      sessionStorage.setItem(
        namespace,
        JSON.stringify([...updatedHistory].slice(0, 10))
      );
    } catch (error) {
      console.error(error);
    }
  }, [url]);

  useEffect(() => {
    if (device.isDesktop === false) {
      const onScroll = () => {
        if (device.scrolling === false) {
          device.set.scrolling(true);
        }
      };
      const onScrollEnd = () => {
        if (device.scrolling) {
          setTimeout(device.set.scrolling, 500, false);
        }
      };

      window.addEventListener("scroll", onScroll, { passive: true });
      window.addEventListener("scrollend", onScrollEnd, { passive: true });

      return () => {
        window.removeEventListener("scroll", onScroll);
        window.removeEventListener("scrollend", onScrollEnd);
      };
    }
  }, [device, device.isDesktop]);

  useReportWebVitals(analytics.reportWebVital);

  return null;
}

export default observer(HooksAppWide);
