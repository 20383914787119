"use client";
import { Divider, SvgIcon, createSvgIcon } from "@mui/material";

export const ModelsIcon = createSvgIcon(
  <SvgIcon viewBox="0 0 18 18">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.2816 0.062545C9.1029 -0.0208483 8.89644 -0.0208483 8.71775 0.062545L0.71775 3.79588C0.48303 3.90541 0.333008 4.14098 0.333008 4.4V13.6C0.333008 13.8591 0.48303 14.0945 0.71775 14.2041L8.71775 17.9375C8.89644 18.0208 9.1029 18.0208 9.2816 17.9375L17.2815 14.2041C17.5163 14.0945 17.6663 13.8591 17.6663 13.6V4.4C17.6663 4.14098 17.5163 3.90541 17.2815 3.79588L9.2816 0.062545ZM8.99967 3.209L6.97354 2.34789L8.99967 1.40236L11.0258 2.34789L8.99967 3.209ZM9.26043 4.54692L12.6506 3.10612L15.3622 4.37154L8.99967 7.07562L2.63712 4.37154L5.34876 3.10612L8.73891 4.54692C8.90554 4.61774 9.0938 4.61774 9.26043 4.54692ZM9.66634 8.24105L16.333 5.40772V9.57289L13.7758 10.7492C13.3027 10.9668 12.9997 11.4398 12.9997 11.9605V14.7309L9.66634 16.2865V8.24105ZM14.333 14.1088L16.333 13.1755V11.0405L14.333 11.9605V14.1088ZM8.333 8.24105V16.2865L5.00276 14.7324V11.9605C5.00276 11.4398 4.69967 10.9668 4.22664 10.7492L1.66634 9.57142V5.40772L8.333 8.24105ZM1.66634 13.1755L3.66943 14.1101V11.9605L1.66634 11.0391V13.1755Z"
    />
  </SvgIcon>,
  "Model"
);

export const DeployIcon = createSvgIcon(
  <SvgIcon height="24" viewBox="0 -960 960 960" width="24">
    <path d="M450.001-177.23v-285.54L200-607.539v278.615q0 3.078 1.539 5.77 1.538 2.693 4.615 4.616L450.001-177.23Zm59.998 0 243.847-141.308q3.077-1.923 4.615-4.616 1.539-2.692 1.539-5.77v-278.615L509.999-462.77v285.54ZM480-514.461 727.001-657.23 486.154-796.538q-3.077-1.923-6.154-1.923t-6.154 1.923L232.999-657.23 480-514.461ZM176.155-265.847q-17.077-9.846-26.615-26.308-9.539-16.461-9.539-36.153v-303.384q0-19.692 9.539-36.153 9.538-16.462 26.615-26.308l267.691-154.076q17.077-9.846 36.154-9.846t36.154 9.846l267.691 154.076q17.077 9.846 26.615 26.308 9.539 16.461 9.539 36.153v303.384q0 19.692-9.539 36.153-9.538 16.462-26.615 26.308L516.154-111.771q-17.077 9.846-36.154 9.846t-36.154-9.846L176.155-265.847ZM480-480Z" />
  </SvgIcon>,
  "Deploy"
);

export const ModelIcon = DeployIcon;

export const ModelDownload = createSvgIcon(
  <SvgIcon height="24" viewBox="0 -960 960 960" width="24">
    <path d="m720-93.078 110.769-110.768-24.923-24.923-68.154 68.153v-156.307h-35.384v156.307l-68.154-68.153-24.923 24.923L720-93.078Zm-246.154-703.46L232.999-657.23 480-514.461 727.001-657.23 486.154-796.538q-3.077-1.923-6.154-1.923t-6.154 1.923Zm-333.845 468.23v-303.384q0-19.692 9.491-36.071 9.49-16.38 26.663-26.39l267.691-154.076q9.231-5 17.751-7.423 8.519-2.423 18.384-2.423t18.903 2.423q9.039 2.423 17.27 7.423l267.691 154.076q17.173 10.01 26.663 26.39 9.491 16.379 9.491 36.071v148.615H760v-124.462l-281 162-279-162v278.615q0 3.078 1.539 5.77 1.538 2.693 4.615 4.616l246.155 142.846v68.537L176.155-265.847q-17.173-10.01-26.663-26.39-9.491-16.379-9.491-36.071ZM720-25.002q-74.922 0-127.461-52.538-52.538-52.538-52.538-127.46 0-74.923 52.538-127.461Q645.078-384.999 720-384.999t127.461 52.538Q899.999-279.923 899.999-205q0 74.922-52.538 127.46Q794.922-25.002 720-25.002ZM480-486.384Z" />
  </SvgIcon>,
  "ModelDownload"
);

export const ModelUser = createSvgIcon(
  <SvgIcon height="24" viewBox="0 -960 960 960" width="24">
    <path d="M671.128-140.54q-7.206 0-14.403-1.744-7.197-1.743-13.495-6.102l-106.922-62.307q-12.7-7.276-19.504-19.554-6.803-12.279-6.803-26.831v-124.844q0-14.552 6.803-26.831 6.804-12.278 19.504-19.554l106.922-62.307q6.31-4.359 13.521-6.102 7.211-1.744 14.422-1.744t13.924 2.179q6.713 2.18 12.979 5.667l106.922 62.307q12.744 7.276 20.026 19.554 7.282 12.279 7.282 26.831v124.844q0 14.552-7.282 26.831-7.282 12.278-20.026 19.554l-106.916 62.359q-6.237 3.563-12.992 5.679-6.756 2.115-13.962 2.115ZM400-492.309q-57.749 0-98.874-41.124-41.125-41.125-41.125-98.874 0-57.75 41.125-98.874 41.125-41.125 98.874-41.125 57.749 0 98.874 41.125 41.125 41.124 41.125 98.874 0 57.749-41.125 98.874-41.125 41.124-98.874 41.124ZM100.001-187.694v-88.922q0-30.307 15.462-54.884 15.461-24.576 43.153-38.038 49.847-24.846 107.692-41.5Q324.154-427.691 400-427.691h11.692q4.846 0 10.462 1.23-6.077 14.154-10.039 28.846-3.961 14.692-6.576 29.922H400q-69.077 0-122.307 15.885-53.231 15.884-91.539 35.807-13.615 7.308-19.885 17.077-6.269 9.77-6.269 22.308v28.923h252q4.461 15.23 11.577 30.922 7.115 15.692 15.653 29.077H100.001ZM400-552.307q33 0 56.5-23.5t23.5-56.5q0-33-23.5-56.5t-56.5-23.5q-33 0-56.5 23.5t-23.5 56.5q0 33 23.5 56.5t56.5 23.5Zm0-80Zm12 384.614Zm167.846-152.153 91.307 53.077 91.308-53.077-91.308-52.461-91.307 52.461Zm115.153 198 89.615-51.754v-109.477l-89.615 53.384v107.847Zm-137.307-51.616 89.615 52.616v-107.847l-89.615-53v108.231Z" />
  </SvgIcon>,
  "Modeluser"
);
export const ModelComingSoon = createSvgIcon(
  <SvgIcon height="24" viewBox="0 -960 960 960" width="24">
    <path d="M737.692-210.928v-110.611h-35.384v124.923L786-112.924l24.923-24.922-73.231-73.082Zm-263.846-585.61L232.999-657.23 480-514.461 727.001-657.23 486.154-796.538q-3.077-1.923-6.154-1.923t-6.154 1.923Zm-333.845 468.23v-303.384q0-19.692 9.491-36.071 9.49-16.38 26.663-26.39l267.691-154.076q9.231-5 17.751-7.423 8.519-2.423 18.384-2.423t18.903 2.423q9.039 2.423 17.27 7.423l267.691 154.076q17.173 10.01 26.663 26.39 9.491 16.379 9.491 36.071v148.615H760v-124.462l-281 162-279-162v278.615q0 3.078 1.539 5.77 1.538 2.693 4.615 4.616l246.155 142.846v68.537L176.155-265.847q-17.173-10.01-26.663-26.39-9.491-16.379-9.491-36.071ZM720-25.002q-74.922 0-127.461-52.538-52.538-52.538-52.538-127.46 0-74.923 52.538-127.461Q645.078-384.999 720-384.999t127.461 52.538Q899.999-279.923 899.999-205q0 74.922-52.538 127.46Q794.922-25.002 720-25.002ZM480-486.384Z" />
  </SvgIcon>,
  "ModelComingSoon"
);

export const DoubleModel = ({
  sx,
  sx1,
  sx2,
  bgcolor = "secondary.on.color"
}) => (
  <>
    <ModelIcon sx={sx1 || sx} />
    <Divider
      orientation="vertical"
      sx={{
        bgcolor,
        height: "auto",
        mx: 0.1
      }}
    />
    <ModelIcon sx={sx2 || sx} />
  </>
);
